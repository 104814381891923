<template>
  <n-card no-margin>
    <div class="border-b pb-4 font-serif flex">
      <h1 class="font-semibold text-xl">
        <n-html :text="$t('details.payment.forms.account_holder')" />
      </h1>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.title.label')" />
        </div>
        <div>{{ customer.title }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.initials.label')" />
        </div>
        <div>{{ customer.initials }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.first_name.label')" />
        </div>
        <div>{{ customer.first_name || "-" }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.middle_name.label')" />
        </div>
        <div>{{ customer.middle_name || "-" }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.last_name.label')" />
        </div>
        <div>{{ customer.surname }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.zip_code.label')" />
        </div>
        <div>{{ customer.zip_code }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.street_number.label')" />
        </div>
        <div>{{ customer.street_number }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.street_number_addition.label')" />
        </div>
        <div>{{ customer.street_number_addition }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.street_name.label')" />
        </div>
        <div>{{ customer.street_name }}</div>
      </div>
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.city.label')" />
        </div>
        <div>{{ customer.city }}</div>
      </div>
    </div>
  </n-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "components.details.payment.card-account-holder",
  computed: {
    ...mapGetters({
      customer: "customer/get"
    })
  }
};
</script>
