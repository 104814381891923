<template>
  <n-card no-margin>
    <div class="border-b pb-4 font-serif flex">
      <h1 class="font-semibold text-xl">
        <n-html :text="$t('details.payment.forms.payment_method')" />
      </h1>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.contract.label')" />
        </div>
        <div>{{ iban.contractNo }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif">
      <div>
        <div class="font-semibold">
          <n-html :text="$t('form.payment_method.label')" />
        </div>
        <div>{{ iban.paymentMethodCode }}</div>
      </div>
    </div>
    <div class="border-b pb-1 pt-3 leading-8 font-serif">
      <div class="flex">
        <div>
          <div class="font-semibold">
            <n-html :text="$t('form.iban.label')" />
          </div>
          <div>{{ iban.iban.replace(/.(?=.{3,}$)/g, "*") }}</div>
        </div>
        <n-button-link
          class="ml-auto my-auto"
          @click.native="showChangeModal(true)"
        >
          <n-html :text="$t('details.common.forms.edit')" />
        </n-button-link>
      </div>
    </div>
    <modal-payment-method-change
      :show="method_modal_visible"
      :onCloseModal="() => showChangeModal(false)"
    />
  </n-card>
</template>

<script>
import ModalPaymentMethodChange from "./modal-payment-method-change";
import validation from "../../../mixins/validation";

export default {
  components: { ModalPaymentMethodChange },
  name: "components.details.payment.card-method",
  mixin: [validation],
  data: () => ({
    edit: false,
    data: {
      iban: ""
    },
    form_states: {
      form: null,
      iban: null
    },
    method_modal_visible: false
  }),
  props: {
    iban: Object
  },
  computed: {
    valid() {
      return this.data.iban && this.validateNotEmpty(this.data.iban);
    }
  },
  watch: {
    data: {
      handler(n) {
        this.form_states.iban = n && this.validateNotEmpty(n.iban);
      },
      deep: true
    },
    edit(n) {
      if (n) {
        this.form_states.form = null;
      }
    }
  },
  methods: {
    showChangeModal(value) {
      this.method_modal_visible = value;
    },
    save() {
      const payload = {
        contractNo: this.iban.contractNo,
        customerNo: this.iban.customerNo,
        iban: this.data.iban
      };
      this.$store
        .dispatch("customer/save_iban", payload)
        .then(() => {
          this.edit = false;
          this.form_states.form = true;
        })
        .catch(() => (this.form_states.form = false));
    }
  }
};
</script>

<style>
.form-iban-icon {
  position: absolute;
  right: 15px;
  top: 2px;
  cursor: pointer;
}
</style>
