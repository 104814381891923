<template>
  <modal :class="show ? '' : 'hidden'" @close="close" :title="getTitle">
    {{ /* Steps */ }}
    <div class="pb-5">
      <div v-if="step === 1">
        <div class="border-b border-brand-gray-200 pb-4">
          <h2
            class="mt-4 text-brand-purple-500 font-semibold text-sm md:text-lg"
          >
            <n-html :text="$t('details.payment.change_modal.new_iban')" />
          </h2>
          <n-form-group
            class="mt-4"
            id="input-group-iban"
            :invalid-feedback="$t('form.iban.feedback.invalid')"
            :state="form_states.iban"
          >
            <n-form-input-default
              id="input-iban"
              :placeholder="$t('form.iban.placeholder')"
              v-model="data.iban"
              type="text"
            />
          </n-form-group>
          <div class="mt-4">
            <n-checkbox
              class="text-xs md:text-base"
              v-model="data.has_agreed"
              :label="$t('details.payment.change_modal.agreement')"
            />
          </div>
        </div>
        <div>
          <h2
            class="mt-4 text-brand-purple-500 font-semibold  text-sm md:text-lg mb-5"
          >
            <n-html
              :text="$t('details.payment.change_modal.for_all_contracts')"
            />
          </h2>
          <div class="mt-2 bm-5">
            <div
              v-for="(option, i) in options"
              class="first:mb-1 md:first:mb-4"
              v-bind:key="i"
            >
              <input
                class="appearance-none rounded-full h-4 w-4 border bg-gray-300 border-gray-300 bg-white checked:bg-brand-purple-500 checked:border-brand-purple-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="radio"
                name="contractOptions"
                @change="selectAllContracts(option.value)"
                :checked="option.value"
              />
              <label
                class="form-check-label inline-block text-gray-800 text-xs md:text-base"
              >
                {{ option.text }}
              </label>
            </div>
          </div>
          <div class="mt-4" v-if="showContractList">
            <div class="grid grid-cols-12 gap-4">
              <CardProductSmall
                @click.native="selectContract(i)"
                v-for="(contract, i) in activeContracts"
                v-bind:key="contract.id"
                :contract="contract"
                dense
                :selected="data.contracts[i]"
              />
            </div>
          </div>
        </div>
        <n-alert v-if="errors.message" class="mt-4" variant="danger">
          <n-html :text="errors.message" />
        </n-alert>
      </div>
      <div v-else>
        <div class="pb-4">
          <p class="text-sm">
            <n-html :text="$t('details.payment.change_modal.success')" />
          </p>
        </div>
      </div>
    </div>
    {{ /* Footer */ }}
    <template v-slot:footer>
      <div class="grid lg:flex items-center w-full items-center">
        <n-button-link
          bold
          @click.native="close"
          class="mx-auto lg:mx-0 order-last lg:order-first mt-4 lg:mt-0"
        >
          <n-html :text="$t('details.payment.change_modal.cancel')" />
        </n-button-link>

        <n-button-regular
          v-if="step < 2"
          @click.native="submit"
          :disabled="!formIsValid"
          class="order-first mx-auto lg:order-last lg:hidden px-14 py-2"
        >
          <n-html :text="$t('details.payment.change_modal.submit_mobile')" />
        </n-button-regular>

        <n-button-regular
          v-if="step < 2"
          @click.native="submit"
          :disabled="!formIsValid"
          class="order-first lg:order-last hidden lg:inline ml-auto"
        >
          <n-html :text="$t('details.payment.change_modal.submit')" />
        </n-button-regular>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import format from "../../../mixins/format";
import validation from "../../../mixins/validation";
import CardProductSmall from "./../../../components/products/card-product-small";
import Modal from "@/components/theme/modal/modal";

var IBAN = require("iban");

export default {
  name: "components.payment.modal-payment-method-change",
  mixin: [format, validation],
  components: {
    CardProductSmall,
    Modal
  },
  props: {
    value: Boolean,
    show: Boolean,
    onCloseModal: Function
  },
  computed: {
    ...mapGetters({
      customer: "customer/get",
      activeContracts: "contracts/getAllActive"
    }),
    getTitle() {
      return this.$t("details.payment.change_modal.title");
    },
    formIsValid() {
      if (!this.data.has_agreed || !IBAN.isValid(this.data.iban)) {
        return false;
      }
      if (!this.data.all_contracts) {
        const items = this.data.contracts.filter(item => item === false);
        if (items.length === this.data.contracts.length) {
          return false;
        }
      }
      return true;
    },
    showContractList() {
      return !this.data.all_contracts;
    }
  },
  mounted() {
    this.activeContracts.forEach(() => {
      this.data.contracts.push(false);
    });
    this.options = [
      {
        text: this.$t(
          "details.payment.change_modal.for_all_contracts_agreement"
        ),
        value: true
      },
      {
        text: this.$t("details.payment.change_modal.for_all_contracts_other"),
        value: false
      }
    ];
  },
  data: () => ({
    step: 1,
    form_states: {
      form: null,
      iban: null
    },
    data: {
      iban: "",
      has_agreed: false,
      all_contracts: true,
      contracts: []
    },
    options: [],
    errors: {
      message: ""
    }
  }),
  methods: {
    selectAllContracts(value) {
      this.data.all_contracts = value;
    },
    selectContract(index) {
      this.data.contracts.splice(index, 1, !this.data.contracts[index]);
    },
    close() {
      this.resetData();
      this.activeContracts.forEach(() => {
        this.data.contracts.push(false);
      });

      this.onCloseModal();
    },
    submit() {
      if (this.data.all_contracts) {
        this.activeContracts.forEach((value, index) => {
          const payload = {
            contractNo: value.navision_ext_id,
            iban: this.data.iban
          };

          this.save(index, this.activeContracts.length, payload);
        });
      } else {
        this.data.contracts.forEach((value, index) => {
          if (value) {
            const contract = this.activeContracts[index];
            const payload = {
              contractNo: contract.navision_ext_id,
              iban: this.data.iban
            };

            const contracts = this.data.contracts.filter(item => item === true);
            this.save(index, contracts.length, payload);
          }
        });
      }
    },
    save(current, total, payload) {
      this.$store
        .dispatch("customer/save_iban", payload)
        .then(() => {
          if (current === total - 1) {
            this.step = 2;
          }
        })
        .catch(e => {
          if (e === "OPEN_REQUEST") {
            this.errors.message = this.$t(
              "details.payment.change_modal.open_request"
            );
          } else {
            this.errors.message = this.$t(
              "details.payment.change_modal.unknown_error"
            );
          }
          this.form_states.form = false;
        });
    },
    resetData() {
      this.step = 1;
      this.form_states = {
        form: null,
        iban: null
      };
      this.data = {
        iban: "",
        has_agreed: false,
        all_contracts: true,
        contracts: []
      };
      this.errors = {
        message: ""
      };
    }
  },
  watch: {
    data: {
      handler(n) {
        this.form_states.iban = n && IBAN.isValid(n.iban);
        this.errors.message = "";
      },
      deep: true
    },
    value(val) {
      this.$emit("input", val);
      if (val === false) {
        setTimeout(() => {
          this.step = 1;
          this.resetData();
        }, 200);
      }
    }
  }
};
</script>
