<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <div class="grid grid-cols-12 grid-rows-1 sm:p-8 p-4 sm:gap-8 gap-0">
      <div
        class="col-span-12 sm:mx-4 sm:my-4 mx-0 my-4 sm:mt-6 mt-2 grid grid-cols-2 sm:gap-12 gap-6"
      >
        <h1
          class="col-span-2 lg:col-span-1 uppercase text-brand-purple-500 font-serif font-bold text-2xl sm:text-3xl"
        >
          <n-html :text="$t('details.payment.title')" />
        </h1>
        <div
          class="col-span-2 lg:col-span-1 grid grid-cols-2 font-serif gap-x-6"
        >
          <div class="flex col-span-2 xl:col-span-1">
            <div class="font-semibold mr-3">
              <n-html :text="$t('details.common.header.customer_number')" />
              :
            </div>
            {{ customer.navision_ext_id }}
          </div>
          <div class="flex col-span-2 xl:col-span-1">
            <div class="font-semibold mr-3">
              <n-html :text="$t('details.common.header.customer_name')" />
              :
            </div>
            {{ customer.full_name }}
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-6 sm:mt-0 mt-4">
        <CardAccountHolder />
      </div>
      <div class="col-span-12 lg:col-span-6 sm:mt-0 mt-4">
        <CardPaymentMethod
          v-for="iban in mergedIbans"
          :key="iban.contractNo"
          :iban="iban"
        />
      </div>
    </div>
  </div>
  <!-- <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <b-container>
      <b-row>
        <b-col cols="12" class="margin-bottom-grid-between">
          <h3 class="typography-h3 details-personal-title">
            <n-html :text='$t("details.payment.title")' />
          </h3>
          <div class="details-personal-title-side">
            <span class="typography-p-regular typography-bold">
              <n-html :text='$t("details.common.header.customer_number")' />:
            </span>
            <span class="typography-p-regular">
              {{ customer.navision_ext_id }}
            </span>
            <span class="typography-p-regular typography-bold">
              <n-html :text='$t("details.common.header.customer_name")' />:
            </span>
            <span class="typography-p-regular">
              {{ customer.full_name }}
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-alert
            show
            variant="primary"
            v-if="customer.discount_percentage > 0"
          >
            {{ discountLine }}
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="padding-bottom-grid-between"
        >
          <CardAccountHolder />
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="6"
          xl="6"
          class="text-center"
          v-if="loading"
        >
          <b-spinner label="Loading..."></b-spinner>
        </b-col>

        <b-col cols="12" sm="12" md="12" lg="6" xl="6" v-else-if="ibans.length">
          <CardPaymentMethod
            v-for="iban in mergedIbans"
            :key="iban.contractNo"
            :iban="iban"
          />
        </b-col>
      </b-row>
    </b-container>
  </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import CardAccountHolder from "./../../components/details/payment/card-account-holder";
import CardPaymentMethod from "./../../components/details/payment/card-payment-method";

export default {
  name: "details.personal",
  components: {
    CardAccountHolder,
    CardPaymentMethod
  },
  data: () => ({
    loading: false,
    mergedIbans: []
  }),
  computed: {
    ...mapGetters({
      customer: "customer/get",
      ibans: "customer/get_ibans"
    }),
    discountLine() {
      return this.$i18n
        .t("insurance.discount.label")
        .replace("{discount}", this.customer.discount_percentage);
    }
  },
  created() {
    this.$store.dispatch("customer/get_ibans").finally(() => {
      this.mergedIbans = this.getMergedIbanList();
      this.loading = false;
    });
  },
  methods: {
    getMergedIbanList() {
      var mergedArray = [];
      this.ibans.forEach(element => {
        const filteredMergeArray = mergedArray.filter(mergedElement => {
          if (mergedElement.iban == element.iban) return mergedElement;
        });
        if (filteredMergeArray.length == 0) {
          mergedArray.push(element);
        } else {
          const index = mergedArray.findIndex(
            e =>
              e.iban === element.iban &&
              e.paymentMethodCode === element.paymentMethodCode
          );
          if (index >= 0) {
            let elementToMerge = mergedArray[index];
            elementToMerge.contractNo =
              elementToMerge.contractNo + `, ${element.contractNo}`;
          } else {
            mergedArray.push(element);
          }
        }
      });
      return mergedArray;
    }
  }
};
</script>

<style>
.details-personal-title {
  display: inline-block;
}

.details-personal-title-side {
  float: right;
  padding-top: 8px;
}

.details-personal-title-side .typography-bold {
  margin-left: 1rem;
  margin-right: 0.5rem;
}
</style>
